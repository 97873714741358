import React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/layout"
import ProjectCard from '../../components/ProjectCard'

// images
import img_feedging_program_19_20 from '../../images/content/projects/2019-20-feeding-program/portraits.jpg'
import img_feedging_program from '../../images/content/feeding_program.jpg'
import img_aeta_outreach from '../../images/content/aeta_outreach.jpg'
import img_school_build_out from '../../images/content/school_build_out.jpg'
import img_aeta_outreach_2013 from '../../images/content/aeta_outreach_2013.jpg'
import img_medical_mission_2012 from '../../images/content/medical_mission_2012.jpg'

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  render() {
    return (
      <Layout>
        <div className="section has-background-light">
          <div className="container">
            <div className="columns is-multiline   ">
              {/* project 6 */}
              <div className="column is-one-third">
                <ProjectCard  title="Feeding Program"
                              year="2019/2020"
                              projectUrl="/projects/2019-20-feeding-program"
                              mainImage={img_feedging_program_19_20}
                              shortDescription="Some short description needs to go here. Something short and sweet. An appetizer but you also can understand the whole purpose."
                              />
              </div>
              {/* project 5 */}
              <div className="column is-one-third">
                <ProjectCard  title="Feeding Program"
                              year="2018/2019"
                              projectUrl="/projects/2018-19-feeding-program"
                              mainImage={img_feedging_program}
                              shortDescription="Some short description needs to go here. Something short and sweet. An appetizer but you also can understand the whole purpose."
                              />
              </div>
              {/* project 4 */}
              <div className="column is-one-third">
                <ProjectCard  title="Aeta Outreach"
                              year="2017"
                              projectUrl="/projects/2017-aeta-outreach"
                              mainImage={img_aeta_outreach}
                              shortDescription="Some short description needs to go here. Something short and sweet. An appetizer but you also can understand the whole purpose."
                              />
              </div>
              {/* project 3 */}
              <div className="column is-one-third"> 
                <ProjectCard  title="School Build Out"
                              year="2015"
                              projectUrl="/projects/2015-school-build-out"
                              mainImage={img_school_build_out}
                              shortDescription="Some short description needs to go here. Something short and sweet. An appetizer but you also can understand the whole purpose."
                              />
              </div>
            
              {/* project 2 */}
              <div className="column is-one-third"> 
                <ProjectCard  title="Aeta Outreach"
                              year="2013"
                              projectUrl="/projects/2013-aeta-outreach"
                              mainImage={img_aeta_outreach_2013}
                              shortDescription="Some short description needs to go here. Something short and sweet. An appetizer but you also can understand the whole purpose."
                              />
              </div>

              {/* project 1 */}
              <div className="column is-one-third"> 
                <ProjectCard  title="Medical Mission"
                              year="2012"
                              projectUrl="/projects/2012-medical-mission"
                              mainImage={img_medical_mission_2012}
                              shortDescription="Some short description needs to go here. Something short and sweet. An appetizer but you also can understand the whole purpose."
                              />  
              </div>

            </div>
          </div>
        </div>
      </Layout>
    );
  }
}