import React, { Component } from 'react'
import { Link } from 'gatsby'

const imgStyle = {
  objectFit: 'cover'
}
export default class ProjectCard extends Component {
  render() {
    return (
      <div className="card">
        <div className="card-image">
          <figure className="image is-4by3">
            <img src={this.props.mainImage} alt="this.props.title" style={imgStyle}/>
          </figure>
        </div>
        <div className="card-content">
          <div className="media">
            <div className="media-content text-center">
              <p className="title is-4 text-center">
                <Link className="title is-4 text-center"  to={this.props.projectUrl}>
                  {this.props.title}
                </Link>
              </p>
              <p className="subtitle is-6">{this.props.year}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
